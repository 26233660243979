import React from 'react'
import {Link} from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import BlogItem from "./BlogItem"

const All = () => {

    const articles = useStaticQuery(graphql`
    {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
            frontmatter {
                title
                tag
                author
                date (formatString: "dddd, MMMM Do YYYY")
                slug
                image {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                }
            }
            excerpt(pruneLength: 160)
            html
            }
        }
    }
    `)



    const allArticles = articles.allMarkdownRemark.nodes.map((item, index) => (
        <BlogItem
        key={index}
        image={item.frontmatter.image.childImageSharp.gatsbyImageData}
        slug={item.frontmatter.slug}
        date={item.frontmatter.date}
        author={item.frontmatter.author}
        alt={item.frontmatter.title}
        title={item.frontmatter.title}
        />
    ))

    return (
        <div className="p-4">
            <span className="h-12">
                <h2 className="md:text-center text-4xl font-header pb-4">Friendliest Blog</h2>
            </span>
            <p className="md:text-center pb-4">
                A blog from your <a href="https://friendliest.app">allergy-friendly companion</a> to The Happiest Place on Earth.
            </p>
            <div className="pb-4">
                <h3 className="text-3xl font-header flex my-2">All posts</h3>
                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-4 gap-y-4 my-2 items-center">
                {allArticles}
                </div>
            </div>
            <div className="text-center text-lg"><Link to="/">Home</Link></div>
        </div>
    )
}

export default All