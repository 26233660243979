import React from "react"
import Layout from "../components/Layout"
import All from "../components/all"
import SEO from "../components/seo"


const Home = () => {
  return(
    <Layout>
      <SEO title="Friendliest.app Blog" />
      <All/>
    </Layout>
  )
}

export default Home